import Focus from '@tiptap/extension-focus'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import type { Editor } from '@tiptap/react'
import { EditorContent, Extension, useEditor } from '@tiptap/react'
import clsx from 'clsx'
import type { Filter } from 'meilisearch'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { CalloutBubbleMenu } from '@/components/shared/components/editor/extentions/callout/Callout'
import EditorImageEmbedDialog from '@/components/shared/components/editor/extentions/EditorImageEmbedDialog'
import EditorTableDialog from '@/components/shared/components/editor/extentions/EditorTableDialog'
import { Emoji } from '@/components/shared/components/editor/extentions/emoji/Emoji'
import SlashCommands from '@/components/shared/components/editor/extentions/SlashCommands/SlashCommands'
import { EditorVideo } from '@/components/shared/components/editor/extentions/video/EditorVideo'
import {
  insertVideoAfterUpload,
  insertVideoFromData,
} from '@/components/shared/components/editor/extentions/video/uploadVideoPlugin'
import BubbleMenuBar from '@/components/shared/components/editor/menu/BubbleMenuBar'
import EditorFixedMenu from '@/components/shared/components/editor/menu/EditorFixedMenu'
import EditorTextOnlyMenuBar from '@/components/shared/components/editor/menu/EditorTextOnlyMenuBar'
import TableMenuBar from '@/components/shared/components/editor/menu/TableMenuBar'
import { allowedVideoFileTypes } from '@/config/appConstants'
import type { IUppyHandlerFunctionParams } from '@/config/uploader/uppy.config'
import { getUppy } from '@/config/uploader/uppy.config'
import HNContext from '@/context/HNContext'
import { useSearchClient } from '@/context/HNSearchContext'
import clsxm from '@/lib/clsxm'
import { removeDuplicatesInArray } from '@/lib/helpers/dataHelpers'
import {
  cleanSuggestionFromSearch,
  DEFAULT_EXTENTIONS,
  editorFileUpload,
  fixTipTapContent,
} from '@/lib/helpers/editorHelper'
import { doAIcompletion, getEmojiList } from '@/models/Common'
import { searchInteractors } from '@/models/User'
import type { IEmojiListItem } from '@/types/common'
import type {
  IEditorActions,
  IEditorMentionConfig,
  IEditorRef,
  IRichTextEditorProps,
  ISuggestionItem,
} from '@/types/editor'
import type { IRootData } from '@/types/organization'
import toaster from '@/utils/toast'

import { CustomImagePlugin, ImageBubbleMenu } from './CustomImagePlugin'
import EditorEmbedDialog from './extentions/EditorEmbedDialog'
import EditorLinkDialog from './extentions/EditorLinkDialog'
import { Mention } from './extentions/mentions/Mention'
import EditorMenuBar from './menu/EditorMenuBar'
import FloatingMenuBar from './menu/FloatingMenuBar'
import {
  insertImageAfterUpload,
  insertImageFromData,
} from './uploadImagePlugin'

const RichTextEditor = forwardRef(
  (props: IRichTextEditorProps, ref: ForwardedRef<IEditorRef>) => {
    const {
      autoFocus = false,
      borderless = false,
      defaultValue = '',
      disabled = false,
      editable = true,
      editorMenu = true,
      editorTextOnlyMenu = false,
      enableImageUpload = true,
      extendedBubbleMenu = false,
      imageResize = false,
      height = 'small',
      mentionsConfig = [],
      onChange,
      onCreated,
      onSubmit,
      placeholder = '',
      resource,
      showBubbleMenu = true,
      showFloatingMenu,
      showFixedMenu,
      variant = 'default',
      imageParams = {},
      className = '',
      containerClassName = '',
      showStats,
      support,
    } = props
    const { searchClient } = useSearchClient()
    const [uploading, setUploading] = useState(false)
    const { organization, userProfile, organizationSetting, organizationPlan } =
      useContext(HNContext) as IRootData
    const perFileLimit =
      organizationPlan?.power_ups.ext_files_storage?.per_file_size_limit || 100
    const linkDialogRef = useRef<any>(null)
    const imageEmbedDialogRef = useRef<any>(null)
    const embedDialogRef = useRef<any>(null)
    const fileUploadRef = useRef<any>(null)
    const videoUploadRef = useRef<any>(null)
    const tableDialogRef = useRef<any>(null)
    const mentionsConfigRef = useRef(mentionsConfig)
    const emojiList = useRef<IEmojiListItem[]>([])

    // Image Uploader
    const handleUpload = (file: File): any => {
      setUploading(true)
      return editorFileUpload(file, organization, imageParams)
        .then((url) => {
          setUploading(false)
          return url
        })
        .catch((err) => {
          toaster.error({ message: err.message })
        })
    }

    const handleLargeUpload = useCallback(
      ({ file, onComplete, onProgress }: IUppyHandlerFunctionParams): any => {
        try {
          const uppy = getUppy({
            kind: 'inline_videos',
            is_editor_attachment: true,
            autoProceed: true,
            resource_type: resource?.type,
            resource_id: resource?.id ? resource?.id : undefined,
            onComplete,
            onProgress,
            restrictions: {
              allowedFileTypes: allowedVideoFileTypes,
              maxFileSize: perFileLimit,
              maxNumberOfFiles: 1,
              minFileSize: 0,
              maxTotalFileSize: null,
              minNumberOfFiles: 0,
              requiredMetaFields: [],
            },
          })
          uppy.addFile(file)
        } catch (e: any) {
          onComplete({ success: false, error: e.message })
        }
      },
      []
    )

    const heightClass = useMemo(() => {
      if (height === 'small')
        return clsxm(
          editable ? 'p-2 sm:px-4 sm:py-3 min-h-[80px]' : '',
          'editors outline-none focus:outline-none focus-visible:outline-none'
        )
      if (height === 'medium')
        return clsxm(
          editable ? 'p-2 sm:px-4 sm:py-3 min-h-[250px]' : '',
          'editors break-normal outline-none focus:outline-none focus-visible:outline-none'
        )
      return 'editors rounded-t break-normal outline-none focus:outline-none focus-visible:outline-none h-full'
    }, [height, editable])

    const searchMention = (
      query: string,
      filters: Filter,
      config: IEditorMentionConfig
    ): Promise<ISuggestionItem[]> => {
      const { indexName, dataCleaner, ...restConfig } = config
      if (
        Boolean(
          organizationSetting.mask_user_details ||
            organizationSetting.partial_mask_user_details
        ) &&
        indexName === 'Customer' &&
        !userProfile.is_member_of_organization
      )
        return Promise.resolve([])

      if (!userProfile.is_csm_of_organization && indexName === 'Customer') {
        return searchInteractors({
          q: query || '',
          limit: 10,
          filter: filters,
          ...restConfig,
        })
          .then((data) =>
            removeDuplicatesInArray(
              data.hits.map((item: any) =>
                cleanSuggestionFromSearch(
                  item,
                  indexName,
                  organization.home_page
                )
              ),
              'id'
            )
          )
          .then((results) => results.map(dataCleaner || ((item) => item)))
      }

      return searchClient
        .index(indexName)
        .search(query, { limit: 10, filter: filters, ...restConfig })
        .then((data) =>
          removeDuplicatesInArray(
            data.hits.map((item) =>
              cleanSuggestionFromSearch(item, indexName, organization.home_page)
            ),
            'id'
          )
        )
        .then((results) => results.map(dataCleaner || ((item) => item)))
    }

    const searchEmoji = (query: string): Promise<ISuggestionItem[]> => {
      return (
        emojiList.current.length
          ? Promise.resolve(emojiList.current)
          : getEmojiList()
      )
        .then((data) => {
          emojiList.current = data
          return data
        })
        .then((emojis: IEmojiListItem[] = []) => {
          if (!query || !query.length) return Promise.resolve(emojis)
          return emojis
            .filter((emoji) =>
              emoji.aliases.some((alias) => alias.includes(query))
            )
            .map((emoji) => ({
              ...emoji,
              matchedAlias: emoji.aliases.find((alias) =>
                alias.includes(query)
              ),
            }))
            .sort(
              (a, b) =>
                (a.matchedAlias || '').length - (b.matchedAlias || '').length
            )
        })
        .then((emojis: IEmojiListItem[]) =>
          emojis.map((emoji) => ({
            id: emoji.emoji,
            subLabel: emoji.aliases.at(0),
            label: emoji.emoji,
            type: 'Emoji',
          }))
        )
        .catch(() => [])
    }
    const parseMentions = (
      _input: any,
      type: 'Customer' | 'FeatureRequest'
    ): string[] => {
      const mentions = (_input.content || []).flatMap((data: any) =>
        parseMentions(data, type)
      )
      if (_input.type === type) {
        mentions.push(_input.attrs.id)
      }
      return mentions
    }

    const getUploadingImages = (_input: any): string[] => {
      const images = (_input.content || []).flatMap((data: any) =>
        getUploadingImages(data)
      )
      if (
        (_input.type === 'image' || _input.type === 'video') &&
        _input.attrs.uploadId
      ) {
        images.push(_input.attrs.uploadId)
      }
      return images
    }

    const getFilters = useCallback(
      (indexName: string) => {
        const config = (mentionsConfigRef.current || []).find(
          (conf) => conf.indexName === indexName
        )
        return config?.filter || [`organization_ids=${organization.id}`]
      },
      [mentionsConfigRef.current]
    )

    const mentions = mentionsConfig.map((config) =>
      Mention({
        name: config.indexName,
        char: config.indexName === 'FeatureRequest' ? '/' : '@',
        handler: (query: string) => {
          if (query.length) {
            return searchMention(query, getFilters(config.indexName), config)
          }
          return Promise.resolve([])
        },
      })
    )

    const actions: IEditorActions = {
      openLinkDialog: () => {
        linkDialogRef.current.openDialog()
      },
      openImageEmbedDialog: () => {
        imageEmbedDialogRef.current.openDialog()
      },
      openEmbedDialog: (platform) => {
        embedDialogRef.current.openDialog(platform)
      },
      openTableCreation: () => {
        tableDialogRef.current.openDialog()
      },
      triggerImageUpload: () => {
        fileUploadRef.current.click()
      },
      triggerVideoUpload: () => {
        videoUploadRef.current.click()
      },
    }

    const handleAIComplete = (editor: Editor, action: string) => {
      if (!resource?.type) return Promise.resolve()
      const { from, to, empty } = editor.state.selection

      if (empty) {
        return Promise.resolve()
      }

      const selectedText = editor.state.doc.textBetween(from, to, ' ')
      return doAIcompletion(selectedText, { ...resource, action }).then(
        (data) => {
          if (data) {
            const { text } = data
            const { schema } = editor.state

            const node = schema.nodes.paragraph?.create({}, schema.text(text))
            if (node) {
              const transaction = editor.state.tr.replaceSelectionWith(node)
              editor.view.dispatch(transaction)
            }
          }
        }
      )
    }

    const editor = useEditor({
      editable,
      content: defaultValue ? fixTipTapContent(defaultValue) : null,
      autofocus: autoFocus,
      extensions: props.extensions || [
        ...DEFAULT_EXTENTIONS,
        CustomImagePlugin(handleUpload),
        EditorVideo(handleLargeUpload),
        ...mentions,
        Emoji({
          name: 'emoji-mention',
          char: ':',
          handler: searchEmoji,
        }),
        SlashCommands.configure({
          suggestion: {
            allow: () => support?.slashCommands !== false,
          },
          triggerPicker: () => {
            fileUploadRef.current?.click()
          },
          openTableCreation: () => {
            tableDialogRef.current.openDialog()
          },
          triggerVideoUpload: () => {
            videoUploadRef.current?.click()
          },
        }),
        Extension.create({
          name: 'enter-handler',
          addKeyboardShortcuts() {
            return {
              'Cmd-Enter': () => {
                if (onSubmit) onSubmit(this.editor.getHTML())
                return true
              },
              'Ctrl-Enter': () => {
                if (onSubmit) onSubmit(this.editor.getHTML())
                return true
              },
            }
          },
        }),
        Placeholder.configure({
          placeholder,
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Focus.configure({
          className: 'has-focus',
          mode: 'deepest',
        }),
      ],
      editorProps: {
        attributes: {
          class: clsx(
            heightClass,
            editable ? 'p-2 sm:p-4' : 'py-2 sm:py-2',
            className
          ),
        },
      },
      onUpdate: ({ editor: _editor }) => {
        if (onChange) onChange(_editor.getHTML())
      },
      onCreate: ({ editor: _editor }) => {
        if (onCreated) onCreated(_editor.getHTML())
      },
    })

    const handleImageUploadFromTrigger = (
      event: React.ChangeEvent<HTMLInputElement>,
      type: 'image' | 'video' | 'file'
    ) => {
      const file = event.target.files?.[0]
      if (!file || !editor) return
      if (type === 'video') {
        insertVideoFromData(editor.view).then((insertedId) => {
          if (!insertedId) return
          insertVideoAfterUpload(
            file,
            editor.view,
            handleLargeUpload,
            insertedId
          )
        })
      } else if (type === 'image') {
        insertImageFromData(editor.view).then((insertedId) => {
          if (!insertedId) return
          insertImageAfterUpload(file, editor.view, handleUpload, insertedId)
        })
      }
    }

    useImperativeHandle(
      ref,
      (): IEditorRef => ({
        isUploading: () => uploading,
        isReady: () => !!editor,
        setValue(value: string) {
          if (!editor) return
          const cleanedValue = fixTipTapContent(value)
          editor.commands.setContent(cleanedValue || '')
          if (onChange) onChange(cleanedValue)
        },
        submit() {
          if (!editor || !onSubmit) return
          const content = editor.getHTML()
          onSubmit(content)
        },
        focus() {
          if (!editor) return
          editor.commands.focus()
        },
        reset() {
          if (!editor) return
          editor.commands.clearContent()
        },
        insertValue(value: string) {
          if (!editor) return
          editor.commands.clearContent()
          editor.commands.insertContent(value || '')
        },
        replaceOrInsertValue(value: string, prevValue?: string) {
          if (!editor) return
          const currentEditorValue = editor.getHTML()

          if (
            prevValue &&
            currentEditorValue !== '<p></p>' &&
            currentEditorValue.includes(prevValue)
          ) {
            editor.commands.setContent(
              currentEditorValue.replace(prevValue, value)
            )
          } else {
            editor.commands.insertContent(value || '')
          }
          if (onChange) onChange(editor.getHTML())
        },
        getValue() {
          if (!editor) return ''
          return editor.getHTML()
        },
        getJSON() {
          if (!editor) return {}
          return editor.getJSON()
        },
        getText() {
          if (!editor) return ''
          return editor.getText()
        },
        getMentions(type: 'Customer' | 'FeatureRequest'): string[] {
          return !editor ? [] : parseMentions(editor.getJSON(), type)
        },
        isReadyToSubmit() {
          const uploadingImages = getUploadingImages(editor?.getJSON())
          if (uploadingImages.length) return false
          return true
        },
      })
    )

    useEffect(() => {
      mentionsConfigRef.current = mentionsConfig
    }, [mentionsConfig])

    if (!editor) return <></>

    return (
      <div
        className={clsx(
          'relative flex flex-col rounded-md bg-snow text-carbon transition duration-200 dark:bg-gray1',
          `border-${variant === 'default' ? 'gray5' : 'yellow8'} hover:border-${
            variant === 'default' ? 'gray5' : 'yellow8'
          }`,
          borderless
            ? 'border-0 border-transparent focus-within:ring-0'
            : 'border border-gray5',
          containerClassName
        )}
      >
        <EditorContent
          disabled={disabled}
          className='h-full w-full overflow-hidden caret-primary'
          editor={editor}
          data-testid='editor-content'
        />
        {showFixedMenu && (
          <EditorFixedMenu
            editor={editor}
            actions={actions}
            support={support}
          />
        )}
        {editorMenu && (
          <EditorMenuBar
            className='bottom-0 min-w-0 overflow-x-auto rounded-b-md bg-snow px-1 pb-1 pt-1.5 scrollbar-hide dark:bg-gray3 md:space-x-2'
            editor={editor}
            enableImageUpload={enableImageUpload}
            actions={actions}
            onAIComplete={resource?.type ? handleAIComplete : undefined}
            editable={editable}
            support={support}
          />
        )}
        {editorTextOnlyMenu && (
          <EditorTextOnlyMenuBar
            className='bottom-0 min-w-0 overflow-x-auto rounded-b-md bg-snow px-1 pb-1 pt-1.5 scrollbar-hide dark:bg-gray3 md:space-x-2'
            editor={editor}
            enableImageUpload={enableImageUpload}
            actions={actions}
            onAIComplete={resource?.type ? handleAIComplete : undefined}
            editable={editable}
            support={support}
          />
        )}
        {showFloatingMenu && (
          <FloatingMenuBar
            className='bottom-0 min-w-0 overflow-x-auto rounded-b-md bg-snow px-1 pb-1 pt-1.5 scrollbar-hide dark:bg-gray3 md:space-x-2'
            editor={editor}
            enableImageUpload={enableImageUpload}
            actions={actions}
            onAIComplete={resource?.type ? handleAIComplete : undefined}
            editable={editable}
            support={support}
          />
        )}
        {!!(showBubbleMenu && !!editor) && (
          <BubbleMenuBar
            className='bottom-0 min-w-0 overflow-x-auto rounded-b-md bg-snow px-1 pb-1 pt-1.5 scrollbar-hide dark:bg-gray3 md:space-x-2'
            editor={editor}
            enableImageUpload={enableImageUpload}
            actions={actions}
            extendedBubbleMenu={extendedBubbleMenu}
            onAIComplete={resource?.type ? handleAIComplete : undefined}
            editable={editable}
            support={support}
          />
        )}
        {editable && <TableMenuBar editor={editor} />}
        <EditorImageEmbedDialog ref={imageEmbedDialogRef} editor={editor} />
        <EditorLinkDialog ref={linkDialogRef} editor={editor} />
        <EditorEmbedDialog ref={embedDialogRef} editor={editor} />
        <EditorTableDialog ref={tableDialogRef} editor={editor} />
        <CalloutBubbleMenu editor={editor} />
        {imageResize && <ImageBubbleMenu editor={editor} />}
        <input
          ref={fileUploadRef}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
              const targetFiles = e.target.files
              Object.keys(targetFiles).map((key: any) => {
                if (e.target.files && key) {
                  return handleImageUploadFromTrigger(e, 'image')
                }
                return false
              })
              fileUploadRef.current.value = ''
            }
          }}
          multiple={true}
          type='file'
          hidden
          accept='image/png, image/jpeg, image/jpg, image/gif'
        />

        <input
          ref={videoUploadRef}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
              const targetFiles = e.target.files
              Object.keys(targetFiles).map((key: any) => {
                if (e.target.files && key) {
                  return handleImageUploadFromTrigger(e, 'video')
                }
                return false
              })
              videoUploadRef.current.value = ''
            }
          }}
          multiple={true}
          type='file'
          hidden
          accept='video/mp4, video/quicktime, video/avi, video/mov, video/wmv, video/flv, video/3gp, video/mkv, video/webm'
        />
        {showStats && (
          <div className='border-gray13 w-full border-t'>
            <p className='font-mono text-xs text-gray10'>
              {editor.storage.characterCount.words()} Words
            </p>
          </div>
        )}
      </div>
    )
  }
)

RichTextEditor.displayName = 'RichTextEditor'
export default RichTextEditor
